<template>
    <!--<v-dialog :modelValue="dialog" @click:outside="close()" max-width="400px" scrollable>-->
    <v-dialog :modelValue="dialog" max-width="400px" persistent scrollable>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    <span class="text-h6">
                        Área Restrita
                    </span>
                </v-toolbar-title>
                <v-btn @click="close()">
                    <v-icon color="red">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @submit.prevent style="overflow: hidden">
                    <v-row>
                        <!--...-->
                        <v-col cols="12" sm="12" md="12" lg="12">
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field variant="underlined"
                                          prepend-icon="mdi-at"
                                          type="email"
                                          label="E-mail"
                                          v-model="form.email"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field v-if="!reset_senha"
                                          variant="underlined"
                                          prepend-icon="mdi-lock"
                                          :append-icon="exibir_senha ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="exibir_senha = !exibir_senha"
                                          :type="exibir_senha ? 'text' : 'password'"
                                          label="Senha"
                                          v-model="form.senha"
                                          :rules="[regras]" />
                            <div class="d-flex justify-end">
                                <v-btn v-if="!reset_senha" @click="reset_senha = true" variant="text" color="grey" size="small">
                                    Esqueci minha senha
                                </v-btn>
                                <v-btn v-if="reset_senha" @click="reset_senha = false" variant="text" color="grey" size="small">
                                    Entrar
                                </v-btn>
                            </div>
                        </v-col>
                        <!--...-->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn v-if="!reset_senha" @click="submit('login')" :loading="loading" type="submit" color="success" variant="tonal">
                    <v-icon right dark>mdi-login-variant</v-icon>
                    Entrar
                </v-btn>
                <v-btn v-if="reset_senha" @click="submit('reset')" :loading="loading" type="submit" color="success" variant="tonal">
                    <v-icon right dark>mdi-send</v-icon>
                    Redefinir Senha
                </v-btn>
                <v-tooltip v-if="reset_senha" v-model="showToolTip_reset" top>
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" @click="showToolTip_reset = true" icon>
                            <v-icon>mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Ao redefinir a senha, uma nova será enviada para o seu e-mail.</span>
                </v-tooltip>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
</template>

<script>
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        props: ['dialog'],
        emits: ['close'],
        components: {
            'slack-show': SlackShow,
        },
        data() {
            return {
                rota: `/api/sessoes`,

                loading: false,
                exibir_senha: false,
                reset_senha: false,

                form: {},
                resultado: null,

                msg: null,
                alertColor: 'red',
                slack: false,

                showToolTip_reset: false,
            }
        },
        watch: {
            'resultado': 'validarResultado'
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            async submit(acao) {
                const { valid } = await this.$refs.form.validate()
                if (!valid) {
                    //this.alerta('Por favor, preencha os dados necessários.', 'red');
                    return;
                }

                this.loading = true;
                this.resultado = null;

                const opcoes = {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify(this.form)
                };

                await fetch(`${this.rota}/${acao}`, opcoes)
                    .then((r) => {
                        if (r.status == 401) {
                            //this.alerta('Sem autorização.', 'red');
                            if (acao == 'reset') {
                                this.alerta('Usuário não encontrado.', 'red');
                            } else {
                                this.alerta('Usuário e/ou senha incorretos.', 'red');
                            }
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            r.json()
                                .then(json => {
                                    this.resultado = json;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            validarResultado() {
                if (this.resultado == null) {
                    return;
                }

                this.form.senha = '';

                this.$store.dispatch('salvarSessao', this.resultado);

                if (this.resultado != null && this.$store.getters.logado == true) {
                    this.form.email = '';
                    this.close();
                } else if (this.reset_senha == true && this.resultado == true) {
                    this.reset_senha = false;
                    this.alerta('Senha enviada por e-mail.', 'green');
                } else {
                    this.form.email = '';
                }
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },

            close() {
                this.$emit('close');
            },
        },
    }
</script>