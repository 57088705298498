<template>
    <!--<v-snackbar @click:outside="slack = false"-->
    <v-snackbar v-model="slack"
                :color="color"
                :timeout="timeout"
                location="center">
        <div v-if="!Array.isArray(msg)">
            {{ msg }}
        </div>
        <div v-if="Array.isArray(msg)">
            <div v-for="(item, index) in msg"
                 v-bind:key="index">
                {{ msg[index] }}
            </div>
        </div>
        <template v-slot:actions>
            <v-btn @click="slack = false" text>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        props: ['modelValue', 'msg', 'color'],
        emits: ['update:modelValue'],
        computed: {
            slack: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
        data() {
            return {
                timeout: 3000,
            }
        },
    }
</script>