<template>
    <!--<v-dialog :modelValue="dialog" @click:outside="close()" max-width="600px" scrollable>-->
    <v-dialog :modelValue="dialog" max-width="600px" persistent scrollable>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    <span class="text-h6">
                        <span v-if="form.id_arquivo == null">Cliente - #{{form.id_cliente}}</span>
                        <span v-if="form.id_arquivo != null">Arquivo - #{{form.id_arquivo}}</span>
                    </span>
                </v-toolbar-title>
                <v-btn @click="close()">
                    <v-icon color="red">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="overflow: hidden">
                <v-row>
                    <!--...-->
                    <v-col cols="12" sm="12" md="12" lg="12">
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field variant="underlined"
                                      type="text"
                                      label="Código do Cliente"
                                      v-model="resultado.codigo"
                                      readonly />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field variant="underlined"
                                      type="text"
                                      label="Senha do Arquivo"
                                      v-model="resultado.senha"
                                      readonly />
                    </v-col>
                    <!--...-->
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="copiar()" color="orange" variant="tonal">
                    <v-icon right dark>mdi-lock</v-icon>
                    Copiar Dados
                </v-btn>
            </v-card-actions>

            <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
        </v-card>
    </v-dialog>
</template>

<script>
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        props: ['modelValue', 'dialog'],
        emits: ['close'],
        components: {
            'slack-show': SlackShow,
        },
        computed: {
            form: {
                get() {
                    return this.modelValue;
                }
            }
        },
        data() {
            return {
                rota_arquivos: `/api/arquivos`,
                rota_clientes: `/api/clientes`,

                loading: false,

                resultado: { codigo: '', senha: '' },

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        watch: {
            'form': 'getView'
        },
        methods: {
            async getView() {
                this.loading = true;
                this.resultado.codigo = '';
                this.resultado.senha = '';

                let url = `${this.rota_arquivos}/acesso/${this.form.id_cliente}/${this.form.id_arquivo}`;                                
                if(this.form.id_arquivo == null) {
                    url = `${this.rota_clientes}/acesso/${this.form.id_cliente}`;
                }

                await fetch(url)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            r.json()
                                .then(json => {
                                    this.resultado = json;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },

            copiar() {
                let texto = `Código: ${this.resultado.codigo} | Senha: ${this.resultado.senha}`;
                //let texto = `${this.resultado.codigo}\n${this.resultado.senha}`;

                try {
                    navigator.clipboard.writeText(texto).then(
                        () => {
                            this.alerta('Dados copiados.', 'green');
                        },
                    );
                } catch (err) {
                    this.alerta('Ocorreu um erro ao copiar o texto.', 'red');
                }
            },

            close() {
                this.$emit('close');
            },
        },
    }
</script>