/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('O aplicativo est� sendo servido do cache por um service worker.')
        },
        registered() {
            console.log('O service worker foi registrado.')
        },
        cached() {
            console.log('O conte�do foi armazenado em cache para uso offline.')
        },
        updatefound() {
            console.log('Um novo conte�do est� sendo baixado.')
        },
        updated() {
            console.log('Um novo conte�do est� dispon�vel; por favor atualize.')
        },
        offline() {
            console.log('Nenhuma conex�o com a Internet encontrada. O aplicativo est� sendo executado no modo offline.')
        },
        error(error) {
            console.error('Erro durante o registro do service worker:', error)
        }
    })
}
