<template>
    <v-app>
        <v-navigation-drawer v-if="usuario.logado"
                             v-model="exibir_menu"
                             :permanent="!mobile">
            <v-list-item>
                Portal de Arquivos
                <template v-slot:append>
                    <v-btn @click.stop="exibir_menu = false"
                           icon="mdi-chevron-left"
                           variant="text">
                    </v-btn>
                </template>
            </v-list-item>

            <v-divider class="simple"></v-divider>

            <v-list>
                <v-list-item v-for="(item, index) in filtroItens"
                             :key="index"
                             @click="exibirTela(item.nome)">
                    <template v-slot:prepend>
                        <v-icon :icon="item.icone"></v-icon>
                    </template>
                    <v-list-item-title v-text="item.titulo"></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar density="comfortable" class="text-center">
            <v-app-bar-nav-icon v-if="usuario.logado && !exibir_menu"
                                @click="exibir_menu = !exibir_menu"
                                icon="mdi-menu">
            </v-app-bar-nav-icon>

            <v-toolbar-title>
                <!--<span v-if="!mobile || !usuario.logado">Portal de Arquivos</span>-->
                Portal de Arquivos
            </v-toolbar-title>

            <v-btn v-if="!usuario.logado"
                   @click.stop="dialog_login = true"
                   text>
                <!--<v-icon right dark>mdi-login-variant</v-icon>-->
                Login
            </v-btn>

            <!--
            <span v-if="usuario.logado">
                {{usuario.dados.nome}}
            </span>
            <v-btn v-if="usuario.logado"
                   @click.stop="logout()"
                   icon="mdi-logout-variant"
                   color="red"
                   variant="text">
            </v-btn>
            -->

            <v-menu v-if="usuario.logado">
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-account" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        {{usuario.dados.nome}}
                    </v-list-item>
                    <v-list-item @click.stop="trocarTema()">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-invert-colors"></v-icon>
                        </template>
                        <v-list-item-title>Trocar Tema</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="dialog_alterar = true">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-key-outline" color="orange"></v-icon>
                        </template>
                        <v-list-item-title>Alterar Senha</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-logout-variant" color="red"></v-icon>
                        </template>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <login-form :dialog="dialog_login" @close="dialog_login = false"></login-form>

        <alterar-form :dialog="dialog_alterar" @close="dialog_alterar = false"></alterar-form>

        <v-main v-if="usuario.logado">
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-main v-if="!usuario.logado && !dialog_login">
            <v-col class="d-flex justify-center align-center" style="padding-top: 15%;">
                <arquivo-view :full="true"></arquivo-view>
            </v-col>
        </v-main>
    </v-app>
</template>

<script>
    import { useDisplay } from 'vuetify'
    import { useTheme } from 'vuetify'
    import LoginForm from '@/components/LoginForm.vue'
    import AlterarSenhaForm from '@/components/AlterarSenhaForm.vue'
    import ArquivoView from '@/components/ArquivoView.vue'

    export default {
        name: 'App',
        components: {
            'login-form': LoginForm,
            'alterar-form': AlterarSenhaForm,
            'arquivo-view': ArquivoView,
        },
        computed: {
            usuario() {
                return this.$store.state.usuario;
            },
            filtroItens() {
                return this.itens.filter(x => this.$store.getters.acesso(x.rota, x.metodo));
            },
            mobile() {
                const { name } = useDisplay();
                return name.value == 'xs' ? true : false;
            },
            dark() {
                const theme = useTheme();
                return theme.global.current.value.dark;
            },
            setTema() {
                const theme = useTheme();
                return () => theme.global.name.value = this.tema;
            },
        },
        data() {
            return {
                rota: `/api/sessoes`,

                loading: false,

                dialog_login: false,
                dialog_alterar: false,

                exibir_menu: false,

                tema: 'dark',

                itens: [
                    { titulo: 'Clientes', nome: 'ClientesList', rota: 'clientes', metodo: 'GET', icone: 'mdi-clipboard-account' },
                    { titulo: 'Funcionários', nome: 'FuncionariosList', rota: 'funcionarios', metodo: 'GET', icone: 'mdi-security' },
                    { titulo: 'Empresas', nome: 'EmpresasList', rota: 'empresas', metodo: 'GET', icone: 'mdi-domain' },
                    { titulo: 'Relatórios', nome: 'RelatoriosList', rota: 'relatorios', metodo: 'GET', icone: 'mdi-chart-box-outline' },
                    { titulo: 'Buscar Arquivo', nome: 'ArquivoView', rota: 'arquivos', metodo: 'GET', icone: 'mdi-file-download' },
                ],
            }
        },
        created() {
            this.getTemaStorage();
            this.$store.dispatch('validarSessao');
        },
        methods: {
            logout() {
                this.loading = true;

                const opcoes = {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                };

                fetch(`${this.rota}/logout`, opcoes)
                    .then(() => {
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })

                this.$store.dispatch('encerrarSessao');
            },

            getTemaStorage() {
                const tema_storage = localStorage.getItem('tema');

                this.tema = 'dark';

                if (tema_storage != null) {
                    if (tema_storage == 'light') {
                        this.tema = 'light';
                    }
                } else {
                    const system_light = window.matchMedia("(prefers-color-scheme: light)");

                    if (system_light.matches) {
                        this.tema = 'light';
                    }
                }

                this.setTema();
            },

            trocarTema() {
                if (this.tema == 'dark') {
                    this.tema = 'light';
                } else {
                    this.tema = 'dark';
                }

                localStorage.setItem('tema', this.tema);
                this.setTema();
            },

            exibirTela(nome) {
                this.$router.push({ name: nome });
            }
        },
    }
</script>

<style>
    .v-divider {
        border-width: 2px !important;
    }

    .simple {
        border-width: thin 0 0 !important;
    }

    @media screen and (max-width: 600px) {
        .v-data-table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .v-data-table td {
            border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
            display: block;
            text-align: right;
            line-height: 48px;
        }

            .v-data-table td::before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
            }

            .v-data-table td:last-child {
                border-bottom: 0;
            }

        .v-data-table tr:not(:first-child) > td:first-child {
            border-top: medium solid rgba(var(--v-border-color), var(--v-border-opacity));
        }
    }
</style>