<template>
    <!--<v-dialog :modelValue="dialog" @click:outside="close()" max-width="600px" scrollable>-->
    <v-dialog :modelValue="dialog" max-width="600px" persistent scrollable>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    <span class="text-h6">
                        Funcionário
                        <span v-if="type == 'add'"> - Novo</span>
                        <span v-if="type == 'edit'"> - #{{form.id}}</span>
                    </span>
                </v-toolbar-title>
                <v-btn @click="close()">
                    <v-icon color="red">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @submit.prevent style="overflow: hidden">
                    <v-row>
                        <!--...-->
                        <v-col cols="12" sm="12" md="12" lg="12">
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-autocomplete variant="underlined"
                                            :items="lista_empresas"
                                            label="Empresa"
                                            v-model="form.id_empresa"
                                            item-title="texto"
                                            item-value="valor"
                                            :rules="[regras]"
                                            :loading="loading"
                                            hide-no-data
                                            hide-details />
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Nome"
                                          v-model="form.nome"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          type="email"
                                          label="E-mail"
                                          v-model="form.email"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-autocomplete variant="underlined"
                                            :items="lista_perfis"
                                            label="Perfil"
                                            v-model="form.id_perfil"
                                            item-title="texto"
                                            item-value="valor"
                                            :rules="[regras]"
                                            :loading="loading"
                                            hide-no-data
                                            hide-details />
                        </v-col>
                        <v-col v-if="type == 'edit'" cols="12" sm="12" md="12" lg="12">
                            <v-switch v-model="form.ativo"
                                      :color="`${form.ativo ? 'green' : 'grey'}`"
                                      :label="`Ativo - ${form.ativo ? 'Sim' : 'Não'}`">
                            </v-switch>
                        </v-col>
                        <!--...-->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="submit()" :loading="loading" type="submit" color="success" variant="tonal">
                    <v-icon right dark>mdi-content-save</v-icon>
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
</template>

<script>
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        props: ['modelValue', 'type', 'dialog'],
        emits: ['update:modelValue', 'reload', 'close'],
        components: {
            'slack-show': SlackShow,
        },
        computed: {
            form: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
        data() {
            return {
                rota: `/api/funcionarios`,

                loading: false,

                lista_empresas: [],
                lista_perfis: [],

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        created() {
            this.loadSelect();
        },
        watch: {
            '$route': 'loadSelect'
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            async loadSelect() {
                this.lista_empresas = await this.getLista('select/empresas');
                this.lista_perfis = await this.getLista('select/perfis');
            },

            async getLista(rota_lista) {
                this.loading = true;

                return await fetch(`${this.rota}/${rota_lista}`)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            return r.json()
                                .then(json => {
                                    this.loading = false;
                                    return json;
                                })
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                    })
            },

            async submit() {
                const { valid } = await this.$refs.form.validate()
                if (!valid) {
                    //this.alerta('Por favor, preencha os dados necessários.', 'red');
                    return;
                }

                this.loading = true;

                const opcoes = {
                    method: this.type == 'add' ? 'POST' : 'PUT',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify(this.form)
                };

                let url = `${this.rota}`;
                if (this.type == 'edit') {
                    url = `${this.rota}/${this.form.id}`;
                }

                await fetch(url, opcoes)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            this.alerta('Operação realizada com sucesso.', 'green');
                            this.reload();
                            this.close();
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },

            reload() {
                this.$emit('reload');
            },

            close() {
                this.$emit('close');
            },
        },
    }
</script>