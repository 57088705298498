<template>
    <!--<v-dialog :modelValue="dialog" @click:outside="close()" max-width="600px" scrollable>-->
    <v-dialog :modelValue="dialog" max-width="600px" persistent scrollable>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    <span class="text-h6">
                        Arquivo
                        <span v-if="type == 'add'"> - Novo</span>
                        <span v-if="type == 'edit'"> - #{{form.id}}</span>
                    </span>
                </v-toolbar-title>
                <v-btn @click="close()">
                    <v-icon color="red">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @submit.prevent style="overflow: hidden">
                    <v-row>
                        <!--...-->
                        <v-col cols="12" sm="12" md="12" lg="12">
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Nome"
                                          v-model="form.nome"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-file-input class="overflow-hidden"
                                          variant="underlined"
                                          label="Arquivo"
                                          v-model="arquivo"
                                          :rules="[regras]"
                                          show-size />
                        </v-col>
                        <!--...-->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="submit()" :loading="loading" type="submit" color="success" variant="tonal">
                    <v-icon right dark>mdi-content-save</v-icon>
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
</template>

<script>
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        props: ['modelValue', 'type', 'dialog'],
        emits: ['update:modelValue', 'reload', 'close'],
        components: {
            'slack-show': SlackShow,
        },
        computed: {
            form: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
        data() {
            return {
                rota: `/api/arquivos`,

                loading: false,

                arquivo: null,

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            async submit() {
                const { valid } = await this.$refs.form.validate()
                if (!valid) {
                    //this.alerta('Por favor, preencha os dados necessários.', 'red');
                    return;
                }

                if (!this.arquivo) {
                    this.alerta('O arquivo não foi selecionado.', 'red');
                    return;
                }

                this.loading = true;

                let form_data = new FormData();
                form_data.append("registro", JSON.stringify(this.form));

                for (let item of this.arquivo) {
                    form_data.append("arquivo", item, item.name);
                }

                const opcoes = {
                    method: 'POST',
                    body: form_data
                };

                this.alerta('Upload em andamento...', 'green');

                try {
                    await fetch(`${this.rota}`, opcoes)
                        .then((r) => {
                            if (r.status == 401) {
                                this.alerta('Sem autorização.', 'red');
                                this.$store.dispatch('validarSessao');
                            } else if (r.status == 404) {
                                this.alerta('Não encontrado.', 'red');
                            } else if (r.status != 200) {
                                this.alerta('Ocorreu um erro na solicitação.', 'red');
                            } else {
                                this.alerta('Operação realizada com sucesso.', 'green');
                                this.reload();
                                this.close();
                            }
                            this.loading = false;
                            return;
                        })
                        .catch(() => {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                            this.loading = false;
                            return;
                        })
                } catch {
                    this.alerta('Erro interno.', 'red');
                    this.loading = false;
                    return;
                }
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },

            reload() {
                this.$emit('reload');
            },

            close() {
                this.$emit('close');
            },
        },
    }
</script>