import { createStore } from 'vuex'

export default createStore({
    state: {
        usuario: {
            logado: false,
            msg: '',
            perfil: {
                acessos: []
            },
            dados: {
                nome: '',
                empresa: '',
            }
        },
    },

    mutations: {
        async validarSessao(state) {
            let valida = false;
            //### TERMINAR: Usar o "localStorage" para armazenar o "state" do usuário. ###

            const opcoes = {
                headers: { "Content-Type": 'application/json' },
            };

            await fetch('/api/sessoes/validar', opcoes)
                .then((r) => {
                    if (r.status == 200) {
                        r.json()
                            .then(json => {
                                state.usuario = json;
                                if (state.usuario.logado == true) {
                                    valida = true;
                                }
                            })
                    }
                    return;
                })
                .catch(() => {
                    return;
                })

            if (!valida) {
                //this.encerrarSessao(state); //### VERIFICAR ###
                state.usuario.logado = false;
                state.usuario.msg = '';
                state.usuario.perfil = { acessos: [] };
                state.usuario.dados = { nome: '', empresa: '' };

                //### ERRO: Loop infinito de recarregamentos de página. ###
            }
        },

        salvarSessao(state, payload) {
            if (payload != null) {
                if (Object.prototype.hasOwnProperty.call(payload, 'logado')) {
                    state.usuario.logado = payload.logado;
                }

                if (Object.prototype.hasOwnProperty.call(payload, 'msg')) {
                    state.usuario.msg = payload.msg;
                }

                if (Object.prototype.hasOwnProperty.call(payload, 'perfil')) {
                    state.usuario.perfil = payload.perfil;
                }

                if (Object.prototype.hasOwnProperty.call(payload, 'dados')) {
                    state.usuario.dados = payload.dados;
                }
            }
        },

        encerrarSessao(state) {
            //### TERMINAR: REMOVER COOKIES ###
            state.usuario.logado = false;
            state.usuario.msg = '';
            state.usuario.perfil = { acessos: [] };
            state.usuario.dados = { nome: '', empresa: '' };            
        },
    },

    actions: {
        //### VERIFICAR: Usar o Login, Logout e Validação da Sessão aqui? ###
        validarSessao(context) {
            context.commit('validarSessao')
        },

        salvarSessao({ commit }, payload) {
            commit('salvarSessao', payload)
        },

        encerrarSessao(context) {
            context.commit('encerrarSessao')
        },
    },

    getters: {
        logado(state) {
            return state.usuario.logado;
        },

        acesso: (state) => (rota, metodo) => {
            if (state.usuario.perfil.acessos == null) {
                return false;
            }

            const item = state.usuario.perfil.acessos.find(x => x.rota.trim().toLowerCase() == rota.trim().toLowerCase());

            if (item != null) {
                return item.acoes.some(x => x.metodos.includes(metodo.trim().toUpperCase()));
            }

            return false;
        }
    },

    modules: {
    }
})