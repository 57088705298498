<template>
    <!--<v-card :style="[full ? (mobile ? 'width: 100%' : 'width: 400px') : '']">-->
    <!--<v-card :style="[(full && lista_arquivos == null) ? (mobile ? 'width: 300px' : 'width: 400px') : '']">-->
    <v-card :style="card_style">
        <v-toolbar>
            <v-toolbar-title>
                <span class="text-h6" v-if="lista_arquivos == null">
                    Buscar Arquivo
                </span>
                <span class="text-h6" v-if="lista_arquivos != null">
                    Baixar Arquivo
                </span>
            </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
            <v-form ref="form" @submit.prevent style="overflow: hidden">
                <v-row v-if="lista_arquivos == null">
                    <!--...-->
                    <v-col cols="12" sm="12" md="12" lg="12">
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field variant="underlined"
                                      prepend-icon="mdi-ticket-account"
                                      type="codigo"
                                      label="Código do Cliente"
                                      v-model="form.codigo"
                                      :rules="[regras]" />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field variant="underlined"
                                      prepend-icon="mdi-file-document"
                                      :append-icon="exibir_senha ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="exibir_senha = !exibir_senha"
                                      :type="exibir_senha ? 'text' : 'password'"
                                      label="Senha do Arquivo"
                                      v-model="form.senha"
                                      :rules="[regras]" />
                    </v-col>
                    <!--...-->
                </v-row>
            </v-form>
            <v-row v-if="lista_arquivos != null">
                <!--...-->
                <v-col cols="12" sm="12" md="12" lg="12">
                    <v-data-table :headers="headers_arquivos"
                                  :items="lista_arquivos"
                                  :items-per-page="itemsPerPage_arquivos"
                                  v-model:page="page_arquivos"
                                  @page-count="pageCount_arquivos = $event"
                                  class="elevation-1"
                                  hide-default-footer>
                        <template #item="{ item }">
                            <tr>
                                <td v-for="(value, key) in item.columns"
                                    class="overflow-hidden"
                                    :key="value"
                                    :data-label="headers_arquivos.filter(e => e.key === key).map(e => e.title)">
                                    <template v-if="key === 'upload' || key === 'validade'">
                                        {{ formatData(value) }}
                                    </template>
                                    <template v-else-if="key === 'acao'">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ props }">
                                                <v-icon @click="submit(item.raw.id)" color="green" v-bind="props">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>Download</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-else>
                                        {{value}}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:bottom>
                            <div class="text-center">
                                <v-pagination v-model="page_arquivos"
                                              :length="pageCount_arquivos"
                                              :total-visible="mobile ? 1 : 3">
                                </v-pagination>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
                <!--...-->
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn v-if="lista_arquivos == null" @click="submit()" :loading="loading" type="submit" color="success" variant="tonal">
                <v-icon right dark>mdi-file-search</v-icon>
                Buscar
            </v-btn>
            <v-btn v-if="lista_arquivos != null" @click="limpar()" :loading="loading" color="orange" variant="tonal">
                <v-icon right dark>mdi-backspace</v-icon>
                Voltar
            </v-btn>
        </v-card-actions>
    </v-card>

    <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
</template>

<script>
    import { useDisplay } from 'vuetify'
    import SlackShow from '@/components/SlackShow.vue'
    import moment from 'moment';

    export default {
        props: ['full'],
        components: {
            'slack-show': SlackShow,
        },
        computed: {
            mobile() {
                const { name } = useDisplay();
                return name.value == 'xs' ? true : false;
            },

            pageCount_arquivos() {
                return Math.ceil(this.lista_arquivos.length / this.itemsPerPage_arquivos)
            },

            card_style() {
                //(full && lista_arquivos == null) ? (mobile ? 'width: 300px' : 'width: 400px') : ''

                if (this.full && (this.mobile || this.lista_arquivos == null)) {
                    return 'width: 400px';
                }

                return '';
            }
        },
        data() {
            return {
                rota: `/api/download`,

                loading: false,
                exibir_senha: false,

                form: {},
                lista_arquivos: null,

                headers_arquivos: [
                    { title: 'Nome', key: 'nome' },
                    { title: 'Extensão', key: 'extensao' },
                    //{ title: 'Tamanho', key: 'tamanho' },
                    { title: 'Validade', key: 'validade' },
                    { title: "Ação", key: "acao", sortable: false },
                ],

                page_arquivos: 1,
                itemsPerPage_arquivos: 5,

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            limpar() {
                this.form = {};
                this.lista_arquivos = null;
            },

            async submit(id_arquivo) {
                const { valid } = await this.$refs.form.validate()
                if (!valid) {
                    //this.alerta('Por favor, preencha os dados necessários.', 'red');
                    return;
                }

                this.loading = true;

                const opcoes = {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify(this.form)
                };

                let url = `${this.rota}`;
                if (id_arquivo != null) {
                    url = `${this.rota}/${id_arquivo}`;
                }

                await fetch(url, opcoes)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else if (id_arquivo != null) {
                            this.alerta('Download em andamento...', 'green');
                            r.blob()
                                .then(blob => {
                                    //...
                                    var url = window.URL.createObjectURL(blob);
                                    //window.location.assign(url);
                                    window.open(url, '_blank'); //### VERIFICAR ###
                                    //...
                                    this.slack = false;
                                    return;
                                })
                        } else {
                            r.json()
                                .then(json => {
                                    this.lista_arquivos = json;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            formatData(valor) {
                if (valor != null) {
                    return moment(valor).format("DD/MM/YYYY HH:mm:ss");
                }
                return '';
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },
        },
    }
</script>