<template>
    <v-row v-on:click="slack = false" class="scroll">
        <v-col>
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <span v-if="filtro.tipo == null" class="text-h6">Relatórios</span>
                        <span v-if="filtro.tipo != null" class="text-h6">Relatório - {{filtro.tipo.descricao}}</span>
                    </v-toolbar-title>
                    <v-btn @click="loadListaFiltro(false)"
                           icon="mdi-cached"
                           color="success">
                    </v-btn>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text>
                    <!--...-->
                    <v-expansion-panels v-model="panel">
                        <v-expansion-panel>
                            <v-expansion-panel-title>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        Filtro de Busca
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-form ref="form_filtros">
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" sm="12" md="6" lg="6">
                                            <v-autocomplete variant="underlined"
                                                            :items="tipos"
                                                            label="Tipo"
                                                            v-model="filtro.tipo"
                                                            item-title="descricao"
                                                            item-value="id"
                                                            return-object
                                                            :rules="[regras]"
                                                            :loading="loading"
                                                            hide-no-data />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6" lg="6" class="d-flex">
                                            <v-checkbox v-model="filtro.check_empresa"
                                                        :color="`${filtro.check_empresa ? 'green' : 'grey'}`"
                                                        hide-details="true" />
                                            <v-autocomplete :disabled="!filtro.check_empresa"
                                                            variant="underlined"
                                                            :items="lista_empresas"
                                                            label="Empresa"
                                                            v-model="filtro.id_empresa"
                                                            item-title="texto"
                                                            item-value="valor"
                                                            :rules="filtro.check_empresa ? [regras] : []"
                                                            :loading="loading"
                                                            hide-no-data
                                                            style="width: 100%" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" class="d-flex">
                                            <v-checkbox v-model="filtro.check_ano"
                                                        :color="`${filtro.check_ano ? 'green' : 'grey'}`"
                                                        hide-details="true" />
                                            <v-text-field :disabled="!filtro.check_ano"
                                                          variant="underlined"
                                                          type="number"
                                                          label="Ano"
                                                          v-model="filtro.ano"
                                                          :rules="filtro.check_ano ? [regras] : []"
                                                          style="width: 100%" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" class="d-flex">
                                            <v-checkbox v-model="filtro.check_mes"
                                                        :color="`${filtro.check_mes ? 'green' : 'grey'}`"
                                                        hide-details="true" />
                                            <v-text-field :disabled="!filtro.check_mes"
                                                          variant="underlined"
                                                          type="number"
                                                          label="Mês"
                                                          v-model="filtro.mes"
                                                          :rules="filtro.check_mes ? [regras] : []"
                                                          style="width: 100%" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" class="d-flex">
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3">
                                            <v-btn @click="loadListaFiltro(true)" :loading="loading" color="success" variant="tonal" hide-details="true" block>
                                                <v-icon right dark>mdi-database-search</v-icon>
                                                Buscar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!--...-->
                    <div v-if="lista">
                        <div v-if="lista.length > 0">
                            <v-data-table :headers="filtro.tipo.headers"
                                          :items="lista"
                                          :items-per-page="itemsPerPage"
                                          v-model:page="page"
                                          @page-count="pageCount = $event"
                                          class="elevation-1"
                                          hide-default-footer>
                                <template #item="{ item }">
                                    <tr>
                                        <td v-for="(value, key) in item.columns"
                                            class="overflow-hidden"
                                            :key="value"
                                            :data-label="filtro.tipo.headers.filter(e => e.key === key).map(e => e.title)">
                                            <template v-if="key === 'ativo'">
                                                {{item.raw.ativo ? 'Sim' : 'Não'}}
                                            </template>
                                            <template v-else-if="key === 'acao'">
                                                <!--Ações-->
                                            </template>
                                            <template v-else>
                                                {{value}}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <div class="text-center">
                                        <v-pagination v-model="page"
                                                      :length="pageCount"
                                                      :total-visible="mobile ? 1 : 3">
                                        </v-pagination>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                        <v-card v-if="lista.length < 1">
                            <v-card-text>
                                Não foram retornados itens com o filtro utilizado.
                            </v-card-text>
                        </v-card>
                    </div>
                    <v-card v-if="loading" style="margin-top: 10px">
                        <v-card-text>
                            Carregando...
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>

        <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
    </v-row>
</template>

<script>
    import { useDisplay } from 'vuetify'
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        components: {
            'slack-show': SlackShow,
        },
        computed: {
            mobile() {
                const { name } = useDisplay();
                return name.value == 'xs' ? true : false;
            },

            pageCount() {
                return Math.ceil(this.lista.length / this.itemsPerPage)
            },
        },
        data() {
            return {
                rota: `/api/relatorios`,

                loading: false,

                lista: null,
                lista_empresas: [],

                page: 1,
                itemsPerPage: 5,

                msg: null,
                alertColor: 'red',
                slack: false,

                panel: [0],

                filtro: {
                    tipo: null,
                    check_empresa: false,
                    id_empresa: null,
                    check_ano: false,
                    ano: null,
                    check_mes: false,
                    mes: null,
                },

                tipos: [
                    {
                        id: 1,
                        nome: 'upload_mes',
                        descricao: 'Uploads por Mês',
                        headers: [
                            { title: 'Empresa', key: 'empresa' },
                            { title: 'Mês do Upload', key: 'mes_upload' },
                            { title: 'Quantidade', key: 'quantidade' },
                        ]
                    },
                ],
            }
        },
        created() {
            this.loadSelect();
        },
        watch: {
            '$route': 'loadSelect'
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            async loadSelect() {
                this.lista_empresas = await this.getLista('select/empresas');
            },

            async loadListaFiltro(validar) {
                //### PROVISÓRIO: Ocorre um erro ao chamar a função no botão de "reload". ###
                if (validar) {
                    const { valid } = await this.$refs.form_filtros.validate()
                    if (!valid) {
                        //this.alerta('Por favor, preencha os dados necessários.', 'red');
                        return;
                    }
                }

                let aux_rota = `${this.filtro.tipo.nome}?`;

                if (this.filtro.check_empresa) {
                    aux_rota += `&id_empresa=${this.filtro.id_empresa}`;
                }

                if (this.filtro.check_ano) {
                    aux_rota += `&ano=${this.filtro.ano}`;
                }

                if (this.filtro.check_mes) {
                    aux_rota += `&mes=${this.filtro.mes}`;
                }

                this.lista = null;
                this.lista = await this.getLista(aux_rota);
            },

            async getLista(rota_lista) {
                this.loading = true;

                return await fetch(`${this.rota}/${rota_lista}`)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            return r.json()
                                .then(json => {
                                    this.loading = false;
                                    return json;
                                })
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                    })
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },
        },
    }
</script>