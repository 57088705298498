<template>
    <div style="z-index: 1" class="background">
        <!--<v-img :src="require('@/assets/background.png')"></v-img>-->
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss">
    .background {
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        height: calc(100vh);
        z-index: -1;
        flex-wrap: wrap;
        opacity: 0.3;

        img {
            width: 100%;
        }
    }
</style>