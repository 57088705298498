<template>
    <v-row v-on:click="slack = false" class="scroll">
        <v-col>
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <span class="text-h6">Clientes</span>
                    </v-toolbar-title>
                    <v-btn @click="novo()"
                           icon="mdi-plus"
                           color="success">
                    </v-btn>
                    <v-btn @click="getLista()"
                           icon="mdi-cached"
                           color="success">
                    </v-btn>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text>
                    <!--...-->
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-title>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        Filtro de Busca
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-row align="center" justify="center">
                                    <v-col cols="12" sm="12" md="4" lg="4">
                                        <v-text-field variant="underlined"
                                                      type="text"
                                                      label="Filtro"
                                                      v-model="filtro.filtro" />
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-switch v-model="filtro.somente_hoje"
                                                  :color="`${filtro.somente_hoje ? 'green' : 'grey'}`"
                                                  :label="`Somente Hoje`"
                                                  hide-details="true" />
                                    </v-col>
                                    <v-col cols="12" sm="12" md="2" lg="2">
                                        <v-switch v-model="filtro.ativos"
                                                  :color="`${filtro.ativos ? 'green' : 'grey'}`"
                                                  :label="`Ativos`"
                                                  hide-details="true" />
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-btn @click="getLista()" :loading="loading" color="success" variant="tonal" hide-details="true" block>
                                            <v-icon right dark>mdi-database-search</v-icon>
                                            Buscar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!--...-->
                    <div v-if="lista">
                        <div v-if="lista.length > 0">
                            <v-data-table :headers="headers"
                                          :items="lista"
                                          :items-per-page="itemsPerPage"
                                          v-model:page="page"
                                          @page-count="pageCount = $event"
                                          class="elevation-1"
                                          hide-default-footer>
                                <template #item="{ item }">
                                    <tr>
                                        <td v-for="(value, key) in item.columns"
                                            class="overflow-hidden"
                                            :key="value"
                                            :data-label="headers.filter(e => e.key === key).map(e => e.title)">
                                            <template v-if="key === 'ativo'">
                                                {{item.raw.ativo ? 'Sim' : 'Não'}}
                                            </template>
                                            <template v-else-if="key === 'acao'">
                                                <v-icon @click="visualizar(item.raw)"
                                                        style="margin-right: 15px;"
                                                        color="green">
                                                    mdi-eye
                                                </v-icon>
                                                <v-icon @click="editar(item.raw)"                                                        
                                                        color="orange">
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <template v-else>
                                                {{value}}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <div class="text-center">
                                        <v-pagination v-model="page"
                                                      :length="pageCount"
                                                      :total-visible="mobile ? 1 : 3">
                                        </v-pagination>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                        <v-card v-if="lista.length < 1">
                            <v-card-text>
                                Não foram retornados itens com o filtro utilizado.
                            </v-card-text>
                        </v-card>
                    </div>
                    <v-card v-if="loading" style="margin-top: 10px">
                        <v-card-text>
                            Carregando...
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>

        <clientes-form v-model="form" :type="type" :dialog="dialog" @reload="getLista()" @close="dialog = false" />

        <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
    </v-row>
</template>

<script>
    import { useDisplay } from 'vuetify'
    import ClientesForm from '@/components/ClientesForm.vue'
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        components: {
            'clientes-form': ClientesForm,
            'slack-show': SlackShow,
        },
        computed: {
            mobile() {
                const { name } = useDisplay();
                return name.value == 'xs' ? true : false;
            },

            pageCount() {
                return Math.ceil(this.lista.length / this.itemsPerPage)
            },
        },
        data() {
            return {
                rota: `/api/clientes`,

                loading: false,

                filtro: {
                    filtro: '', //null, //### VERIFICAR: O filtro não retorna resultados com o valor nulo. ###
                    somente_hoje: false,
                    ativos: true
                },

                form: {},
                type: 'add',
                dialog: false,

                lista: null,
                headers: [
                    { title: '#ID', key: 'id', align: "start" },
                    //{ title: 'Empresa', key: 'empresa.nome_fantasia' },
                    { title: 'Nome', key: 'nome' },
                    //{ title: 'Identificação', key: 'identificacao' },
                    { title: 'Ativo', key: 'ativo' },
                    { title: "Ação", key: "acao", sortable: false },
                ],

                page: 1,
                itemsPerPage: 5,

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        created() {
            this.getLista();
        },
        watch: {
            '$route': 'getLista'
        },
        methods: {
            novo() {
                this.form = {};
                this.type = 'add';
                this.dialog = true;
            },

            visualizar(item) {
                this.$router.push({ name: 'ClienteView', params: { id: item.id } });
            },

            editar(item) {
                this.form = item;
                this.type = 'edit';
                this.dialog = true;
            },

            async getLista() {
                this.loading = true;
                this.lista = null;

                await fetch(`${this.rota}?filtro=${this.filtro.filtro}&somente_hoje=${this.filtro.somente_hoje}&ativos=${this.filtro.ativos}`)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            r.json()
                                .then(json => {
                                    this.lista = json;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },
        },
    }
</script>