<template>
    <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <span class="text-h6">
                            Cliente
                            <span> - #{{$route.params.id}}</span>
                        </span>
                    </v-toolbar-title>
                    <v-btn @click="visualizarSenha()"
                           icon="mdi-lock"
                           color="orange">
                    </v-btn>
                    <v-btn @click="editarCliente()"
                           icon="mdi-pencil"
                           color="orange">
                    </v-btn>
                    <v-btn @click="getViewCliente()"
                           icon="mdi-cached"
                           color="success">
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row v-if="view_cliente">
                        <!--...-->
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Nome"
                                          v-model="view_cliente.nome"
                                          readonly />
                        </v-col>
                        <!--
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Identificação"
                                          v-model="view_cliente.identificacao"
                                          readonly />
                        </v-col>
                        -->
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Criação"
                                          v-model="format_criacao"
                                          readonly />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          type="text"
                                          label="Modificação"
                                          v-model="format_modificacao"
                                          readonly />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-switch v-model="view_cliente.ativo"
                                      :color="`${view_cliente.ativo ? 'green' : 'grey'}`"
                                      :label="`Ativo - ${view_cliente.ativo ? 'Sim' : 'Não'}`"
                                      readonly>
                            </v-switch>
                        </v-col>
                        <!--...-->
                    </v-row>
                    <v-card v-if="loading">
                        <v-card-text>
                            Carregando...
                        </v-card-text>
                    </v-card>
                    <v-card v-if="!loading && !view_cliente">
                        <v-card-text>
                            Não foram retornados dados na consulta.
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>

        <!--...-->

        <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <span class="text-h6">Arquivos</span>
                    </v-toolbar-title>
                    <v-btn @click="novoArquivo()"
                           icon="mdi-plus"
                           color="success">
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                    <div v-if="lista_arquivos">
                        <div v-if="lista_arquivos.length > 0">
                            <v-data-table :headers="headers_arquivos"
                                          :items="lista_arquivos"
                                          :items-per-page="itemsPerPage_arquivos"
                                          v-model:page="page_arquivos"
                                          @page-count="pageCount_arquivos = $event"
                                          class="elevation-1"
                                          hide-default-footer>
                                <template #item="{ item }">
                                    <tr>
                                        <td v-for="(value, key) in item.columns"
                                            class="overflow-hidden"
                                            :key="value"
                                            :data-label="headers_arquivos.filter(e => e.key === key).map(e => e.title)">
                                            <template v-if="key === 'upload' || key === 'validade'">
                                                {{ formatData(value) }}
                                            </template>
                                            <template v-else-if="key === 'acao'">
                                                <v-icon @click="visualizarArquivo(item.raw)"
                                                        style="margin-right: 15px;"
                                                        color="green">
                                                    mdi-file-download
                                                </v-icon>
                                                <!--
                                                <v-icon @click="visualizarSenha(item.raw)"
                                                        style="margin-right: 15px;"
                                                        color="orange">
                                                    mdi-lock
                                                </v-icon>
                                                -->
                                                <v-icon @click="excluirArquivo(item.raw)"
                                                        color="red">
                                                    mdi-delete-forever
                                                </v-icon>
                                            </template>
                                            <template v-else>
                                                {{value}}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <div class="text-center">
                                        <v-pagination v-model="page_arquivos"
                                                      :length="pageCount_arquivos"
                                                      :total-visible="mobile ? 1 : 3">
                                        </v-pagination>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                        <v-card v-if="lista_arquivos.length < 1">
                            <v-card-text>
                                Não foram retornados itens na consulta.
                            </v-card-text>
                        </v-card>
                    </div>
                    <v-card v-if="loading" style="margin-top: 10px">
                        <v-card-text>
                            Carregando...
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-col>

        <!--...-->

        <clientes-form v-model="view_cliente" type="edit" :dialog="dialog_cliente" @reload="getViewCliente()" @close="dialog_cliente = false" />

        <arquivo-form v-model="form_arquivo" type="add" :dialog="dialog_arquivo" @reload="getViewCliente()" @close="dialog_arquivo = false" />

        <arquivo-delete v-model="form_arquivo" :dialog="dialog_delete" @reload="getViewCliente()" @close="dialog_delete = false" />

        <senha-view v-model="form_senha" :dialog="dialog_senha" @close="dialog_senha = false" />

        <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
    </v-row>
</template>

<script>
    import { useDisplay } from 'vuetify'
    import ClientesForm from '@/components/ClientesForm.vue'
    import ArquivoForm from '@/components/ArquivoForm.vue'
    import ArquivoDelete from '@/components/ArquivoDelete.vue'
    import SenhaView from '@/components/SenhaView.vue'
    import SlackShow from '@/components/SlackShow.vue'
    import moment from 'moment';

    export default {
        components: {            
            'clientes-form': ClientesForm,
            'arquivo-form': ArquivoForm,
            'arquivo-delete': ArquivoDelete,
            'senha-view': SenhaView,
            'slack-show': SlackShow,
        },
        computed: {
            mobile() {
                const { name } = useDisplay();
                return name.value == 'xs' ? true : false;
            },

            pageCount_arquivos() {
                return Math.ceil(this.lista_arquivos.length / this.itemsPerPage_arquivos)
            },

            format_criacao() {
                if (this.view_cliente != null && this.view_cliente.criacao != null) {
                    return moment(this.view_cliente.criacao).format("DD/MM/YYYY HH:mm:ss");
                }
                return '';
            },

            format_modificacao() {
                if (this.view_cliente != null && this.view_cliente.modificacao != null) {
                    return moment(this.view_cliente.modificacao).format("DD/MM/YYYY HH:mm:ss");
                }
                return '';
            },
        },
        data() {
            return {
                rota_cliente: `/api/clientes`,
                rota_arquivos: `/api/arquivos`,

                loading: false,

                form_arquivo: {},
                form_senha: {},

                dialog_cliente: false,
                dialog_arquivo: false,
                dialog_senha: false,
                dialog_delete: false,

                view_cliente: null,
                lista_arquivos: null,

                headers_arquivos: [
                    { title: '#ID', key: 'id', align: "start" },
                    { title: 'Nome', key: 'nome' },
                    { title: 'Extensão', key: 'extensao' },
                    //{ title: 'Tamanho', key: 'tamanho' },
                    { title: 'Upload', key: 'upload' },
                    { title: 'Validade', key: 'validade' },
                    { title: "Ação", key: "acao", sortable: false },
                ],

                page_arquivos: 1,
                itemsPerPage_arquivos: 5,

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        created() {
            this.getViewCliente();
        },
        watch: {
            '$route': 'getViewCliente'
        },
        methods: {
            visualizarSenha(item) {
                if(item == null) {
                    this.form_senha = { id_cliente: this.view_cliente.id, id_arquivo: null };                             
                } else {
                    this.form_senha = { id_cliente: item.id_cliente, id_arquivo: item.id };
                }                
                this.dialog_senha = true;
            },

            novoArquivo() {
                this.form_arquivo = { id_cliente: this.view_cliente.id };
                this.dialog_arquivo = true;
            },

            excluirArquivo(item) {
                this.form_arquivo = item;
                this.dialog_delete = true;
            },

            editarCliente() {
                this.dialog_cliente = true;
            },

            async getViewCliente() {
                this.loading = true;
                //this.view_cliente = null; //### ERRO ###
                //this.lista_arquivos = null,

                    await fetch(`${this.rota_cliente}/${this.$route.params.id}`)
                        .then((r) => {
                            if (r.status == 401) {
                                this.alerta('Sem autorização.', 'red');
                                this.$store.dispatch('validarSessao');
                            } else if (r.status == 404) {
                                this.alerta('Não encontrado.', 'red');
                            } else if (r.status != 200) {
                                this.alerta('Ocorreu um erro na solicitação.', 'red');
                            } else {
                                r.json()
                                    .then(json => {
                                        this.view_cliente = json;
                                        this.lista_arquivos = json.arquivos;
                                    })
                            }
                            this.loading = false;
                            return;
                        })
                        .catch(() => {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                            this.loading = false;
                            return;
                        })
            },

            async visualizarArquivo(item) {
                this.loading = true;

                const opcoes = {
                    headers: { "Content-Type": 'application/json' },
                };

                await fetch(`${this.rota_arquivos}/download/${item.id}`, opcoes)
                    .then((r) => {
                        if (r.status == 401) {
                            this.alerta('Sem autorização.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Arquivo não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            this.alerta('Download em andamento...', 'green');
                            r.blob()
                                .then(blob => {
                                    //...
                                    /*
                                    var url = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = `${item.nome}${item.extensao}`; //### VERIFICAR ###
                                    document.body.appendChild(link);
                                    link.click();
                                    link.remove();
                                    */
                                    //...
                                    var url = window.URL.createObjectURL(blob);
                                    //window.location.assign(url);
                                    window.open(url, '_blank'); //### VERIFICAR ###
                                    //...
                                    this.slack = false;
                                    return;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro na solicitação.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            formatData(valor) {
                if (valor != null) {
                    return moment(valor).format("DD/MM/YYYY HH:mm:ss");
                }
                return '';
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },
        },
    }
</script>