<template>
    <!--<v-dialog :modelValue="dialog" @click:outside="close()" max-width="600px" scrollable>-->
    <v-dialog :modelValue="dialog" max-width="600px" persistent scrollable>
        <v-card>
            <v-toolbar>
                <v-toolbar-title>
                    <span class="text-h6">
                        Alterar Senha
                    </span>
                </v-toolbar-title>
                <v-btn @click="close()">
                    <v-icon color="red">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @submit.prevent style="overflow: hidden">
                    <v-row>
                        <!--...-->
                        <v-col cols="12" sm="12" md="12" lg="12">
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-text-field variant="underlined"
                                          prepend-icon="mdi-lock"
                                          :append-icon="exibir_senha ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="exibir_senha = !exibir_senha"
                                          :type="exibir_senha ? 'text' : 'password'"
                                          label="Senha Atual"
                                          v-model="form.senha"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          prepend-icon="mdi-lock"
                                          :append-icon="exibir_nova_senha ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="exibir_nova_senha = !exibir_nova_senha"
                                          :type="exibir_nova_senha ? 'text' : 'password'"
                                          label="Nova Senha"
                                          v-model="form.nova_senha"
                                          :rules="[regras]" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field variant="underlined"
                                          prepend-icon="mdi-lock"
                                          :append-icon="exibir_confirmacao ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:append="exibir_confirmacao = !exibir_confirmacao"
                                          :type="exibir_confirmacao ? 'text' : 'password'"
                                          label="Repita a Nova Senha"
                                          v-model="form.confirmacao"
                                          :rules="[regra_confirmacao]" />
                        </v-col>
                        <!--...-->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="submit()" :loading="loading" type="submit" color="success" variant="tonal">
                    <v-icon right dark>mdi-content-save</v-icon>
                    Alterar Senha
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <slack-show v-model="slack" :msg="msg" :color="alertColor"></slack-show>
</template>

<script>
    import SlackShow from '@/components/SlackShow.vue'

    export default {
        props: ['dialog'],
        emits: ['close'],
        components: {
            'slack-show': SlackShow,
        },
        data() {
            return {
                rota: `/api/sessoes`,

                loading: false,
                exibir_senha: false,
                exibir_nova_senha: false,
                exibir_confirmacao: false,

                form: {},
                resultado: null,

                msg: null,
                alertColor: 'red',
                slack: false,
            }
        },
        watch: {
            'resultado': 'validarResultado'
        },
        methods: {
            regras(v) {
                return !!v || 'Campo obrigatório'
            },

            regra_confirmacao(v) {
                if (!v) {
                    return 'Campo obrigatório';
                } else if (v != this.form.nova_senha) {
                    return 'As senhas não estão iguais.';
                } else {
                    return true;
                }
            },

            async submit() {
                const { valid } = await this.$refs.form.validate()
                if (!valid) {
                    //this.alerta('Por favor, preencha os dados necessários.', 'red');
                    return;
                }

                if (this.form.nova_senha != this.form.confirmacao) {
                    this.alerta('As senhas não estão iguais.', 'red');
                    return;
                }

                this.loading = true;
                this.resultado = null;

                const opcoes = {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify(this.form)
                };

                await fetch(`${this.rota}/nova_senha`, opcoes)
                    .then((r) => {
                        if (r.status == 401) {
                            //this.alerta('Sem autorização.', 'red');
                            this.alerta('Senha atual incorreta.', 'red');
                            this.$store.dispatch('validarSessao');
                        } else if (r.status == 404) {
                            this.alerta('Não encontrado.', 'red');
                        } else if (r.status != 200) {
                            this.alerta('Ocorreu um erro na solicitação.', 'red');
                        } else {
                            r.json()
                                .then(json => {
                                    this.resultado = json;
                                })
                        }
                        this.loading = false;
                        return;
                    })
                    .catch(() => {
                        this.alerta('Ocorreu um erro.', 'red');
                        this.loading = false;
                        return;
                    })
            },

            validarResultado() {
                if (this.resultado == null) {
                    return;
                }

                if (this.resultado == true) {
                    this.alerta('Senha alterada.', 'green');
                }

                this.close();
            },

            alerta(msg, color) {
                this.msg = msg;
                this.alertColor = color;
                this.slack = true;
            },

            close() {
                this.form.senha = '';
                this.form.nova_senha = '';
                this.form.confirmacao = '';

                this.$emit('close');
            },
        },
    }
</script>