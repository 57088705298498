import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";

import { createRouter, createWebHistory } from 'vue-router'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { VDataTable } from 'vuetify/labs/VDataTable'
import { vMaska } from 'maska'


import ClientesList from '@/components/ClientesList.vue'
import FuncionariosList from '@/components/FuncionariosList.vue'
import EmpresasList from '@/components/EmpresasList.vue'
import RelatoriosList from '@/components/RelatoriosList.vue'
import ArquivoView from '@/components/ArquivoView.vue'
import ClienteView from '@/components/ClienteView.vue'
import HomeView from '@/components/HomeView.vue'
import './registerServiceWorker'


const routes = [
    { path: '/', name: 'Home', component: HomeView },
    { path: '/clientes/list', name: 'ClientesList', component: ClientesList },
    { path: '/funcionarios/list', name: 'FuncionariosList', component: FuncionariosList },
    { path: '/empresas/list', name: 'EmpresasList', component: EmpresasList },
    { path: '/relatorios/list', name: 'RelatoriosList', component: RelatoriosList },
    { path: '/arquivo/view', name: 'ArquivoView', component: ArquivoView },
    { path: '/cliente/view/:id', name: 'ClienteView', component: ClienteView },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

const vuetify = createVuetify({
    components: {
        ...components,
        VDataTable,
    },
    directives,
    theme: {
        defaultTheme: 'dark'
    },
})

createApp(App).use(store).use(router).use(vuetify).directive("maska", vMaska).mount('#app')